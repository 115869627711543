var _ = require('underscore');
module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="js-blog-article blog-article ' +
((__t = ( postType )) == null ? '' : __t) +
' ';
 if(!listImage.url) { ;
__p += 'blog-article-no-image';
 };
__p += '">\n    <div class="blog-article-inner">\n        <div class="blog-article-cont js-blog-article-cont">\n            ';
 if(listImage.url) { ;
__p += '\n                <div class="header-image">\n                    <img alt="" class="js-lazyload" data-src="' +
((__t = ( listImage.url )) == null ? '' : __t) +
'">\n                </div>\n            ';
 } ;
__p += '\n            <div class="sum-author">\n                ';
 if (!author.imageUrl) { ;
__p += '\n                    <div class="profile-pic"></div>\n                ';
 } else { ;
__p += '\n                    <div class="profile-pic" style="background-image: url(\'' +
((__t = ( author.imageUrl )) == null ? '' : __t) +
'\')"></div>\n                ';
 } ;
__p += '\n                ';
 if (!author.profileUrl) { ;
__p += '\n                    <p class="post-author-name">' +
((__t = ( author.name )) == null ? '' : __t) +
'</p>\n                ';
 } else { ;
__p += '\n                    <a class="post-author-name" href="' +
((__t = ( author.profileUrl )) == null ? '' : __t) +
'">' +
((__t = ( author.name )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n                ';
 if (BC.site.code != 'competitivecyclist') { ;
__p += '\n                    <span class="post-meta">\n                        ' +
((__t = ( dateFinal )) == null ? '' : __t) +
'\n                    </span>\n                ';
 } ;
__p += '\n            </div>\n            <div class="sum-title">\n                <h2>\n                    <a class="post-link js-post-link" href="/' +
((__t = ( blogUrl )) == null ? '' : __t) +
'/' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'</a>\n                </h2>\n                ';
 if (BC.site.code == 'competitivecyclist') { ;
__p += '\n                    <span class="post-meta">' +
((__t = ( dateFinal )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n            </div>\n            <p>' +
((__t = ( excerpt )) == null ? '' : __t) +
'</p>\n        </div>\n    </div>\n</div>';

}
return __p
};

/*
original source:

<div class="js-blog-article blog-article {{ postType }} {% if(!listImage.url) { %}blog-article-no-image{% }%}">
    <div class="blog-article-inner">
        <div class="blog-article-cont js-blog-article-cont">
            {% if(listImage.url) { %}
                <div class="header-image">
                    <img alt="" class="js-lazyload" data-src="{{ listImage.url }}">
                </div>
            {% } %}
            <div class="sum-author">
                {% if (!author.imageUrl) { %}
                    <div class="profile-pic"></div>
                {% } else { %}
                    <div class="profile-pic" style="background-image: url('{{ author.imageUrl }}')"></div>
                {% } %}
                {% if (!author.profileUrl) { %}
                    <p class="post-author-name">{{ author.name }}</p>
                {% } else { %}
                    <a class="post-author-name" href="{{ author.profileUrl }}">{{ author.name }}</a>
                {% } %}
                {% if (BC.site.code != 'competitivecyclist') { %}
                    <span class="post-meta">
                        {{ dateFinal }}
                    </span>
                {% } %}
            </div>
            <div class="sum-title">
                <h2>
                    <a class="post-link js-post-link" href="/{{ blogUrl }}/{{ url }}" title="{{ title }}">{{ title }}</a>
                </h2>
                {% if (BC.site.code == 'competitivecyclist') { %}
                    <span class="post-meta">{{ dateFinal }}</span>
                {% } %}
            </div>
            <p>{{ excerpt }}</p>
        </div>
    </div>
</div>
*/
