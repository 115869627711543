var _ = require('underscore');
module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="js-blog-article blog-article ' +
((__t = ( postType )) == null ? '' : __t) +
' ';
 if(!listImage.url) { ;
__p += 'blog-article-no-image';
 };
__p += '">\n  <div class="blog-article-cont js-blog-article-cont">\n    <div class="header-image">\n      <a class="post-link js-lazyload" href="/explore/' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'" ';
 if (listImage.url) { ;
__p += 'data-background-image="' +
((__t = (listImage.url)) == null ? '' : __t) +
'"';
 } ;
__p += '></a>\n      <div class="post-hover-wrap hidden-medium-down">\n        <a href="#" class="post-read-btn btn-transparent">' +
((__t = ( $.t(postType === 'videos' ? 'label.watch' : 'label.read') )) == null ? '' : __t) +
'</a>\n        <p class="post-activities">' +
((__t = ( activitiesStr )) == null ? '' : __t) +
'</p>\n      </div>\n    </div>\n\n    <p class="post-activities hidden-small-up">' +
((__t = ( activitiesStr )) == null ? '' : __t) +
'</p>\n    <h4 class="sum-title">\n      <a href="/explore/' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'</a>\n    </h4>\n    <h5>' +
((__t = ( authorName )) == null ? '' : __t) +
'</h5>\n    <p class="blog-excerpt">' +
((__t = ( excerpt )) == null ? '' : __t) +
'</p>\n  </div>\n</div>\n';

}
return __p
};

/*
original source:

<div class="js-blog-article blog-article {{ postType }} {% if(!listImage.url) { %}blog-article-no-image{% }%}">
  <div class="blog-article-cont js-blog-article-cont">
    <div class="header-image">
      <a class="post-link js-lazyload" href="/explore/{{ url }}" title="{{ title }}" {% if (listImage.url) { %}data-background-image="{{listImage.url}}"{% } %}></a>
      <div class="post-hover-wrap hidden-medium-down">
        <a href="#" class="post-read-btn btn-transparent">{{ $.t(postType === 'videos' ? 'label.watch' : 'label.read') }}</a>
        <p class="post-activities">{{ activitiesStr }}</p>
      </div>
    </div>

    <p class="post-activities hidden-small-up">{{ activitiesStr }}</p>
    <h4 class="sum-title">
      <a href="/explore/{{ url }}" title="{{ title }}">{{ title }}</a>
    </h4>
    <h5>{{ authorName }}</h5>
    <p class="blog-excerpt">{{ excerpt }}</p>
  </div>
</div>

*/
