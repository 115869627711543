var _ = require('underscore');
module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += ' <div class="js-blog-article blog-article ' +
((__t = ( postType )) == null ? '' : __t) +
'">\n  ';
 if (mainImage.url) { ;
__p += '\n    <div class="header-image">\n      ';
 if (has('dev')) { ;
__p += '\n        <a class="post-link" href="/Store/community/explorePost.jsp?urlSlug=' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">\n          <img class="js-lazyload" data-src="' +
((__t = ( mainImage.url )) == null ? '' : __t) +
'">\n        </a>\n      ';
 } else { ;
__p += '\n        <a class="post-link" href="/' +
((__t = ( blogUrl )) == null ? '' : __t) +
'/' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">\n          <img class="js-lazyload" data-src="' +
((__t = ( mainImage.url )) == null ? '' : __t) +
'">\n        </a>\n      ';
 } ;
__p += '\n    </div>\n  ';
 } ;
__p += '\n</div>\n\n';

}
return __p
};

/*
original source:

 <div class="js-blog-article blog-article {{ postType }}">
  {% if (mainImage.url) { %}
    <div class="header-image">
      {% if (has('dev')) { %}
        <a class="post-link" href="/Store/community/explorePost.jsp?urlSlug={{ url }}" title="{{ title }}">
          <img class="js-lazyload" data-src="{{ mainImage.url }}">
        </a>
      {% } else { %}
        <a class="post-link" href="/{{ blogUrl }}/{{ url }}" title="{{ title }}">
          <img class="js-lazyload" data-src="{{ mainImage.url }}">
        </a>
      {% } %}
    </div>
  {% } %}
</div>


*/
