var _ = require('underscore');
module.exports = function (obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="js-blog-article blog-article ' +
((__t = ( postType )) == null ? '' : __t) +
' ';
 if(!listImage.url) { ;
__p += 'blog-article-no-image';
 };
__p += '">\n  <div class="blog-article-inner">\n    <div class="blog-article-cont js-blog-article-cont">\n      <div class="sum-author">\n        ';
 if (!author.imageUrl) { ;
__p += '\n            <div class="profile-pic"></div>\n        ';
 } else { ;
__p += '\n            <div class="profile-pic" style="background-image: url(\'' +
((__t = ( author.imageUrl )) == null ? '' : __t) +
'\')"></div>\n        ';
 } ;
__p += '\n        <h5>\n            ';
 if (!author.profileUrl) { ;
__p += '\n                ' +
((__t = ( author.name )) == null ? '' : __t) +
'\n            ';
 } else { ;
__p += '\n                <a href="' +
((__t = ( author.profileUrl )) == null ? '' : __t) +
'">' +
((__t = ( author.name )) == null ? '' : __t) +
'</a>\n            ';
 } ;
__p += '\n        </h5>\n        <span class="post-meta">\n            ' +
((__t = ( dateFinal )) == null ? '' : __t) +
'\n        </span>\n      </div>\n      ';
 if (listImage.url) { ;
__p += '\n        ';
 if (has('dev')) { ;
__p += '\n          <a class="post-link" href="/Store/community/explorePost.jsp?urlSlug=' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = (title)) == null ? '' : __t) +
'">\n            <img class="blog-tile-screenshot js-lazyload" data-src="' +
((__t = ( listImage.url )) == null ? '' : __t) +
'">\n          </a>\n        ';
 } else { ;
__p += '\n          <a class="post-link" href="/' +
((__t = ( blogUrl )) == null ? '' : __t) +
'/' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = (title)) == null ? '' : __t) +
'">\n            <img class="blog-tile-screenshot js-lazyload" data-src="' +
((__t = ( listImage.url )) == null ? '' : __t) +
'">\n          </a>\n        ';
 } ;
__p += '\n            <div class="sum-title">\n              <h4>\n                ';
 if (has('dev')) { ;
__p += '\n                    <a href="/Store/community/explorePost.jsp?urlSlug=' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'</a>\n                ';
 } else { ;
__p += '\n                    <a href="/' +
((__t = ( blogUrl )) == null ? '' : __t) +
'/' +
((__t = ( url )) == null ? '' : __t) +
'" title="' +
((__t = ( title )) == null ? '' : __t) +
'">' +
((__t = ( title )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n              </h4>\n            </div>\n            <p>' +
((__t = ( excerpt )) == null ? '' : __t) +
'</p>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n</div>\n\n';

}
return __p
};

/*
original source:

<div class="js-blog-article blog-article {{ postType }} {% if(!listImage.url) { %}blog-article-no-image{% }%}">
  <div class="blog-article-inner">
    <div class="blog-article-cont js-blog-article-cont">
      <div class="sum-author">
        {% if (!author.imageUrl) { %}
            <div class="profile-pic"></div>
        {% } else { %}
            <div class="profile-pic" style="background-image: url('{{ author.imageUrl }}')"></div>
        {% } %}
        <h5>
            {% if (!author.profileUrl) { %}
                {{ author.name }}
            {% } else { %}
                <a href="{{ author.profileUrl }}">{{ author.name }}</a>
            {% } %}
        </h5>
        <span class="post-meta">
            {{ dateFinal }}
        </span>
      </div>
      {% if (listImage.url) { %}
        {% if (has('dev')) { %}
          <a class="post-link" href="/Store/community/explorePost.jsp?urlSlug={{ url }}" title="{{title}}">
            <img class="blog-tile-screenshot js-lazyload" data-src="{{ listImage.url }}">
          </a>
        {% } else { %}
          <a class="post-link" href="/{{ blogUrl }}/{{ url }}" title="{{title}}">
            <img class="blog-tile-screenshot js-lazyload" data-src="{{ listImage.url }}">
          </a>
        {% } %}
            <div class="sum-title">
              <h4>
                {% if (has('dev')) { %}
                    <a href="/Store/community/explorePost.jsp?urlSlug={{ url }}" title="{{ title }}">{{ title }}</a>
                {% } else { %}
                    <a href="/{{ blogUrl }}/{{ url }}" title="{{ title }}">{{ title }}</a>
                {% } %}
              </h4>
            </div>
            <p>{{ excerpt }}</p>
      {% } %}
    </div>
  </div>
</div>


*/
