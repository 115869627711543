import Controller from 'common/controllers';
import dynamicPageMapping from 'common/util/dynamic-page-mapping';

import { getPageName } from 'common/bc.core';
import { renderWithTrackingContext } from 'common/components/utils';

import 'vendors/jquery.dotdotdot';
import 'vendors/jquery.smoothDivScroll';
import 'common/widgets/bc.blogpagination';
import 'common/widgets/bc.lazyloader';
import 'common/widgets/bc.sticky';

class BcsCommunityExploreLandingController extends Controller {
  constructor() {
    super({
      root: '.explore-v2.js-explore-v2',
    });
  }

  ready() {
    $('.js-lazyload').lazyloader({
      auto: true,
      proximity: 200,
    });
    this._toggleCategories();
    BC.subscribe('init.explore-landing.page', this.initPage, this);
  }

  // this is not needed in the method functionality.
  // eslint-disable-next-line class-methods-use-this
  _toggleCategories() {
    const btnToggle = $('.show-categories');
    const targetContainer = $('.categories');

    btnToggle.on('click', () => {
      btnToggle.attr('aria-expanded', (i, attr) => (attr == 'true' ? 'false' : 'true'));
      targetContainer.toggleClass('collapsed');
      btnToggle.toggleClass('collapsed');
    });
  }

  // TODO selectors should be referenced on the initialization of the contoller and then accessed here on the class instance
  // eslint-disable-next-line class-methods-use-this
  updateHeadPaginationLinks(currentPage, totalPages) {
    const fullpath = `${window.location.origin}${window.location.pathname}`;
    const relCanonical = document.querySelector('link[rel="canonical"]');
    let relNext = document.querySelector('link[rel="next"]');
    let relPrev = document.querySelector('link[rel="prev"]');

    if (currentPage > 0) {
      if (!relPrev) {
        relPrev = document.createElement('link');
        relPrev.rel = 'prev';
        relCanonical.parentNode.insertBefore(relPrev, relCanonical.nextSibling);
      }
      relPrev.href = `${fullpath}?page=${currentPage - 1}`;
    } else {
      relPrev.remove();
    }
    if (currentPage < totalPages - 1) {
      if (!relNext) {
        relNext = document.createElement('link');
        relNext.rel = 'next';
        relCanonical.parentNode.insertBefore(relNext, relCanonical.nextSibling);
      }
      relNext.href = `${fullpath}?page=${currentPage + 1}`;
    } else {
      relNext.remove();
    }
  }

  initPage({ ...props }) {
    const pageName = getPageName();
    const { loader } = dynamicPageMapping.exploreLanding;
    const params = { pageName, linkNameComponent: pageName };
    const componentProps = {
      ...props,
      updateHeadPaginationLinks: this.updateHeadPaginationLinks,
    };

    // TODO change to DynamicContent page and use and Lazy internally
    loader().then(({ default: page }) => renderWithTrackingContext(page, document.querySelector(this.selectors.root), componentProps, params));
  }
}

export default BcsCommunityExploreLandingController;
