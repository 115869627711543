// bc.blogpagination
// ===================
// Widget to generate the pagination for the community blog

define([
  'common/widgets/bc.blogarticlepost.tpl',
  'common/widgets/bc.blogvideopost.tpl',
  'common/widgets/bc.blogimagepost.tpl',
  'common/widgets/bc.blogTile.tpl',
  'common/util/bc.url.utils',
  'underscore',
  'vendors/masonry',
  'common/main',
  'common/util/truncate',
  'common/widgets/bc.ajaxloader',
  'common/widgets/bc.lazyloader',
], function(articlePostTemplate, videoPostTemplate, imagePostTemplate, tileTemplate, urlUtils, {
  each,
  isFunction,
  throttle,
  unescape,
}) {
  $.widget('bc.blogpagination', {
    options: {
      animate: true,
      blogUrl: 'explore',
      callback: $.noop,
      cutPaginationAfter: 10,
      filters: {
        activities: '', authorTypes: '', postTypes: '', topics: '',
      },
      isExploreV2: false,
      loadInitial: true,
      pagesArea: 5,
      pageSize: 12,
      scrollTopElement: '',
      site: 'bcs',
      useMasonry: true,
      hostRegex: /^(http:|https:)?\/\/[\w-.]*/,
    },

    _create() {
      var self = this;

      self._state = {
        currentPage: 1,
        isLoading: false,
      };
      self.$postWorkspace = $('#post-workspace');
      self.$window = $(window);
      self._loadQueryString();

      if (self.options.isExploreV2) {
        self._initInfiniteScroll();
      } else {
        self._state.currentPage = parseInt(document.location.hash.substring(5, 999), 10) || parseInt(urlUtils.inUrl('page'), 10) || 1;

        // When you click on the pagination links
        self._on(document, {
          'click .js-pagination .page-link a': '_goToPage',
        });
      }

      // Call the service and build the pagination
      if (self.options.loadInitial) {
        self.loadPage(self._state.currentPage, self.options.filters);
      }
    },

    _destroy() {
      this._abortActiveXhr();
      this._unbindInfiniteScrollHandler();
    },

    // PUBLIC

    /**
         * reloads current pageNumber page
         *
         * @param pageNumber int The number of the page to load
         * @param filters json The filters object. Filters: activities, authorTypes, postTypes, topics
         *
         * */
    loadPage(pageNumber, filters) {
      var self = this;

      if (!pageNumber || pageNumber < 1) {
        pageNumber = 1;
      }

      if (filters) {
        self.options.filters = filters;
      }

      if (pageNumber === 1 && self.options.isExploreV2) {
        self._initInfiniteScroll();
      }

      // call _consultingService and then _buildPagination and _gettingContentIn on callback
      self._consultingService(pageNumber, filters).then(function(data) {
        var totalPages = data.totalPages;

        self._buildPagination(data, pageNumber, totalPages);
        self._gettingContentIn(data);
      });
    },

    // PRIVATE

    _isProd() {
      return (/^(www|preview)\.(backcountry|competitivecyclist)\.com$/).test(window.location.hostname);
    },

    _shouldUpdateImageHost() {
      return this._isProd() && BC.page.id === 'community-explore-landing';
    },

    /**
         * Aborts the activeXhr request if it exists.
         * @returns {undefined}
         */
    _abortActiveXhr() {
      var xhr = this._state.activeXhr;

      xhr && isFunction(xhr.abort) && xhr.abort();
    },

    /**
         * Builds the pagination widget html
         *
         * @param data json
         * @param pageNumber int
         * @param totalPages int
         * @param cutPaginationAfter int
         * @param pagesArea int
         *
         * */
    _buildPagination(data, pageNumber, totalPages) {
      var self = this;
      var $element = self.element;
      var startPage;
      var endPage;
      var pagItems;

      // pagination not needed in v2, we use infinite scrolling instead
      if (self.options.isExploreV2) {
        return;
      }

      $element.html('<ul/>');

      // First we build the numbers
      if (totalPages <= self.options.cutPaginationAfter) {
        if (totalPages < self.options.cutPaginationAfter) {
          endPage = totalPages;
        } else {
          endPage = self.options.cutPaginationAfter;
        }
        startPage = 1;
        pagItems = self._buildingPageNumbers(startPage, endPage, pageNumber);
      } else { // more than cutPaginationAfter... here we go
        // setting the start and end of the pagination
        if (pageNumber - self.options.pagesArea < 1) {
          startPage = 1;
          endPage = self.options.pagesArea * 2;
        } else if (pageNumber + self.options.pagesArea > totalPages) {
          startPage = totalPages - (self.options.pagesArea * 2);
          endPage = totalPages;
        } else {
          startPage = pageNumber - self.options.pagesArea;
          endPage = pageNumber + self.options.pagesArea;
        }

        pagItems = self._buildingPageNumbers(startPage, endPage, pageNumber);
      }

      // ****************************************************************
      // Then we add the specials stuff, like (...), prev, next, etc...
      // ****************************************************************

      if (totalPages > self.options.cutPaginationAfter) {
        // Adding the 'hidden pages', first and last item (...)
        // On the left
        if (startPage >= 2) {
          pagItems = '<li><a href="#page1">1</a></li><li class="hidden-pages"><span class="pag-active">..</span></li>' + pagItems;
        }
        // On the right
        if (endPage < totalPages) {
          pagItems += '<li class="hidden-pages"><span class="pag-active">..</span></li><li><a href="#page' + totalPages + '">' + totalPages + '</a></li>';
        }
      }

      // Adding next and prev buttons
      // Prev
      if (pageNumber > 1) {
        pagItems = '<li class="pag-prev"><a class="pag-btn" href="#page' + (pageNumber - 1) + '">' + $.t('label.page.prev') + '</a></li>' + pagItems;
      }
      // Next
      if (pageNumber < totalPages) {
        pagItems += '<li class="pag-next"><a class="pag-btn" href="#page' + (pageNumber + 1) + '">' + $.t('label.page.next') + '</a></li>';
      }

      // Hitting the DOM, is this one hit?
      $element.find('ul')
        .append(pagItems)
        .find('li:not(.current-page)')
        .addClass('page-link page-number');
    },

    _buildingPageNumbers(pageStart, pageEnd, pageNumber) {
      var pagItems = ''; // resetting the string every time we build the numbers

      for (var i = pageStart; i <= pageEnd; i++) {
        if (i == 1 && pageNumber == 1) {
          pagItems = '<li class="current-page"><a aria-current="page" href="#page' + i + '" class="pag-active">' + i + '</a></li>';
        } else if (i == 1) {
          pagItems = '<li><a href="#page' + i + '">' + i + '</a></li>';
        } else if (i != 1 && pageNumber == i) {
          pagItems += '<li class="current-page"><a aria-current="page" href="#page' + i + '" class="pag-active">' + i + '</a></li>';
        } else {
          pagItems += '<li><a href="#page' + i + '">' + i + '</a></li>';
        }
      }

      return pagItems;
    },

    /**
         * Consults posts json service
         *
         * @param pageNumber int The desired page number
         * @param filters json The filters object. Filters: activities, authorTypes, postTypes, topics
         * @return {jqxhr<object>} posts data
         */
    _consultingService(pageNumber, filters) {
      var self = this;

      self._loadQueryString();

      return $.ajax({
        url: '/Store/community/explore/posts.json.jsp',
        data: {
          activities: filters.activities,
          authorTypes: filters.authorTypes,
          site: self.options.site,
          pageSize: self.options.pageSize,
          page: pageNumber,
          postTypes: filters.postTypes,
          topics: filters.topics,
        },
      }).then((response) => {
        if (this._shouldUpdateImageHost()) {
          response.posts.forEach((post) => {
            if (post.listImage && post.listImage.url) {
              post.listImage.url = post.listImage.url.replace(this.options.hostRegex, `//content.${BC.site.domain}`);
            }
          });
        }

        return response;
      });
    },

    /**
         * Formats the date for the post
         * */
    _formatDate(datePublished) {
      var monthNames = [
        $.t('january'),
        $.t('february'),
        $.t('march'),
        $.t('april'),
        $.t('may'),
        $.t('june'),
        $.t('july'),
        $.t('august'),
        $.t('september'),
        $.t('october'),
        $.t('november'),
        $.t('december'),
      ];
      var dt = new Date(datePublished);
      var dateFinal = monthNames[dt.getMonth()] + ' ' + dt.getDate() + ', ' + dt.getFullYear();

      return dateFinal;
    },

    /**
         * Parses underscore template and inserts content into DOM
         * @param data json
         */
    _gettingContentIn(data) {
      var postsMarkup = this._renderPostsData(data);

      this._insertPosts(postsMarkup, false);
      this._scrollToTop();

      // Callback function to do stuff after finishing populating the DOM.
      this.options.callback();
    },

    /**
         * Gets page number, consults service and then builds pagination and loads posts
         * @param event event
         */
    _goToPage(event) {
      var self = this;
      var $element = $(event.currentTarget);
      var pageNumber = parseInt($element.attr('href').substring(5, 999), 10);

      self.loadPage(pageNumber, self.options.filters);
    },

    /**
         * Initializes inifinite scrolling for posts. Resets currentPage counter, aborts any
         * pending xhr requests, and unbinds any previous scroll handlers for infinite scroll.
         * Should be called each time a new posts filter is applied.
         * @returns {undefined}
         */
    _initInfiniteScroll() {
      var self = this;
      var state = self._state;
      var postWorkspaceOffset = self.$postWorkspace.offset().top;

      self._abortActiveXhr();
      self._unbindInfiniteScrollHandler();

      // reset current page
      state.currentPage = 1;

      // init ajaxloader
      if (!self.$postWorkspace.data('bc-ajaxloader')) {
        self.$postWorkspace.ajaxloader({
          size: 'large',
          style: 'dark',
          customCss: { margin: '0 auto' },
        });
      }

      state.infiniteScrollHandler = throttle(function() {
        if (self._shouldFetchNextPosts(postWorkspaceOffset)) {
          state.isLoading = true;
          state.currentPage++;
          self.$postWorkspace.ajaxloader('show');

          state.activeXhr = self._consultingService(state.currentPage, self.options.filters)
            .then(function(data) {
              self.$postWorkspace.ajaxloader('hide');
              self._insertPosts(self._renderPostsData(data), true);

              // reached last page of posts
              if (state.currentPage >= data.totalPages) {
                self._unbindInfiniteScrollHandler();
              }
              state.isLoading = false;
            });
        }
      }, 100);

      self.$window.on('scroll', state.infiniteScrollHandler);
    },

    /**
         * Inserts or optionally appends posts to the workspace.
         * @param {string} postsMarkup - rendered posts markup
         * @param {boolean} shouldAppend - if the posts should be appended or
         * @returns {undefined}
         */
    _insertPosts(postsMarkup, shouldAppend) {
      this.$postWorkspace[shouldAppend ? 'append' : 'html'](postsMarkup);

      if (this.options.useMasonry) {
        this.$postWorkspace.masonry('reload');
      }

      this._lazyloadImages();
    },

    _lazyloadImages() {
      var self = this;

      setTimeout(function() {
        self.$postWorkspace.find('.js-lazyload').lazyloader({
          auto: true,
          proximity: 200,
        });
      }, 0);
    },

    /**
         * Loads url query string parameters to look for blog filters
         * */
    _loadQueryString() {
      var filters = this.options.filters;

      this.options.filters = {
        activities: urlUtils.inUrl('activities') || filters.activities || '',
        authorTypes: urlUtils.inUrl('authorTypes') || filters.authorTypes || '',
        postTypes: urlUtils.inUrl('postTypes') || filters.postTypes || '',
        topics: urlUtils.inUrl('topics') || filters.topics || '',
      };
    },

    _renderPostsData(data) {
      var self = this;
      var labelAuthor = $.t('post.author.label') || '';
      var readMoreLabel = $.t('post.read.more') || '';
      var postsMarkup = '';

      each(data.posts, function(post) {
        post.labelAuthor = labelAuthor;
        post.readMoreLabel = readMoreLabel;
        post.blogUrl = self.options.blogUrl;
        post.dateFinal = self._formatDate(post.datePublished);

        if (self.options.isExploreV2) {
          switch (post.postType) {
            case 'articles':
            case 'images':
            case 'videos':
            case 'creative_templates':
              post.excerpt = jQuery.truncate(post.excerpt, { length: 130, words: true });
              post.authorName = post.authorName || '';
              post.activitiesStr = post.activities ? post.activities.join(' // ') : '';
              postsMarkup += tileTemplate(post);
              break;
            default:
              break;
          }
        } else {
          switch (post.postType) {
            case 'articles':
            case 'creative_templates':
              postsMarkup += articlePostTemplate(post);
              break;
            case 'images':
              postsMarkup += imagePostTemplate(post);
              break;
            case 'videos':
              postsMarkup += videoPostTemplate(post);
              break;
            default:
              break;
          }
        }
      });

      return unescape(postsMarkup);
    },

    _scrollToTop() {
      var self = this;
      var pageNumber = 0;

      if (self.options.animate) {
        if (self.options.scrollTopElement && self.options.scrollTopElement !== '') {
          pageNumber = parseInt(document.location.hash.substring(5, 999), 10);
          if (pageNumber && pageNumber > 0) {
            $('html, body').animate({ scrollTop: $(self.options.scrollTopElement).offset().top - 100 }, 'slow');
          }
        } else {
          $('html, body').animate({ scrollTop: 0 }, 'slow');
        }
      }
    },

    /**
         * Determines if the the next page of posts should be fetched for infinite scrolling.
         * @param {number} postWorkspaceOffset - the top offset in px of the postWorkspace
         * @returns {boolean}
         */
    _shouldFetchNextPosts(postWorkspaceOffset) {
      if (this._state.isLoading) {
        return false;
      }

      var windowHeight = this.$window.height();
      var currentScroll = this.$window.scrollTop() + windowHeight;
      var targetBottomOffset = postWorkspaceOffset + this.$postWorkspace.height() - (windowHeight / 2);

      return currentScroll >= targetBottomOffset;
    },

    /**
         * Unbinds the active infiniteScrollHandler
         * @returns {undefined}
         */
    _unbindInfiniteScrollHandler() {
      if (this._state.infiniteScrollHandler) {
        this.$window.off('scroll', this._state.infiniteScrollHandler);
      }
    },
  });
});
