define([
  'common/util/scroll-utils',
  'common/main',
  'vendors/jquery.stickem',
  'waypoints',
  'waypoints-sticky',
],
function() {
  $.widget('bc.sticky', {
    options: {
      baseClass: 'ui-sticky',
      handler: $.noop,
      itemSelector: '',
      peek: false,
      peekTransition: 'all .4s',
      priority: 99,
      scrollBuffer: 25, // px
      scrollUpBuffer: 10, // px
      stuckOffset: 0,
      stuckClass: 'stuck',
      endClass: 'end',
      containerElement: '',
      wrapperClass: '',
    },

    _create() {
      var self = this;
      var options = this.options;

      self.state = {};
      self.classes = self._makeClasses();

      if (options.containerElement) {
        self.$containerElement = $(options.containerElement);
        self.$containerElement.stickem({
          item: options.itemSelector,
          container: options.containerElement,
          endStickClass: self.classes.endStick,
          stickClass: self.classes.stuck,
          offset: options.stuckOffset,
        });
      } else {
        self.element
          .addClass(self.classes.base)
          .waypoint('sticky', {
            handler: self._handler.bind(self),
            offset: options.stuckOffset,
            stuckClass: self.classes.stuck,
            wrapper: '<div class="' + self.classes.wrapper + ' ' + options.wrapperClass + '"/>',
          });
      }

      if (options.peek) {
        self.element.css({
          transition: options.peekTransition,
        });
        $.subscribe('scrollUtils.scroll', self._handleScrollPeeking, self);
        $.subscribe('instantsearch.open', function() { self.state.instantsearch = true; });
        $.subscribe('instantsearch.closed', function() { self.state.instantsearch = false; });
      }

      $.subscribe('sticky.show', self._handleStickyShow, self);
      $.subscribe('sticky.hide', self._handleStickyHide, self);
    },

    _makeClasses() {
      var options = this.options;

      return {
        base: options.baseClass,
        endStick: options.baseClass + '--' + options.endClass,
        stuck: options.baseClass + '--' + options.stuckClass,
        wrapper: options.baseClass + '-wrapper',
        peek: options.baseClass + '--peek',
        peekVisible: options.peekVisibleClass,
      };
    },

    _destroy() {
      var self = this;
      var options = self.options;

      if (options.containerElement && self.$containerElement.stickem) {
        self.$containerElement.stickem('destroy');
      } else if (self.element.waypoint) {
        self.element.waypoint('destroy');
        self.element.unwrap('.' + self.classes.wrapper);
      }

      $.unsubscribe('scrollUtils.scroll', self._handleScrollPeeking, self);
      $.unsubscribe('instantsearch.open', function() { self.state.instantsearch = true; });
      $.unsubscribe('instantsearch.closed', function() { self.state.instantsearch = false; });
      $.unsubscribe('sticky.show', self._handleStickyShow, self);
      $.unsubscribe('sticky.hide', self._handleStickyHide, self);

      _(self.classes).forEach(function(c) {
        self.element.removeClass(c);
      });
    },

    hide() {
      this.element.removeClass(this.classes.peek)
        .css('top', -this.element.outerHeight());

      $.publish('sticky.hide', {
        priority: this.options.priority,
        uuid: this.uuid,
      });
    },

    isStuck() {
      return this.element.hasClass(this.classes.stuck);
    },

    show() {
      this.element.addClass(this.classes.peek);

      $.publish('sticky.show', {
        transition: this.options.peekTransition,
        height: this.element.outerHeight(),
        priority: this.options.priority,
        uuid: this.uuid,
      });
    },

    stick() {
      this.element.addClass(this.classes.stuck);
    },

    unstick() {
      this.element.removeClass(this.classes.stuck);
    },

    _handler(direction) {
      if (this.options.peek && direction === 'up') {
        this.element.css({ top: '0' });
      }
      this.options.handler(direction);
    },

    _handleScrollPeeking(payload) {
      var state = this.state;

      state.prevScroll = state.prevScroll || payload.prevScroll;

      if (payload.direction === 'up' && payload.currentScroll < state.prevScroll - this.options.scrollUpBuffer) {
        this.show();
        state.prevScroll = payload.currentScroll;

        return;
      }

      var scrolledPastBuffer = payload.currentScroll > state.prevScroll + this.options.scrollBuffer;
      var scrolledPastElement = payload.currentScroll > this.element.parent().offset().top + this.element.height();

      if (scrolledPastBuffer && scrolledPastElement) {
        if (!state.instantsearch) {
          this.hide();
        }
        state.prevScroll = payload.currentScroll;
      }
    },

    _handleStickyHide(payload) {
      var options = this.options;

      if (this.uuid === payload.uuid) {
        return;
      }

      if (payload.priority < options.priority) {
        this.element.css({ top: options.stuckOffset });

        if (!this.isStuck()) {
          this.element.css({
            transition: options.peek ? options.peekTransition : '',
          });
        }
      }
    },

    _handleStickyShow(payload) {
      if (this.uuid === payload.uuid || !this.isStuck()) {
        return;
      }

      if (payload.priority < this.options.priority && payload.height) {
        this.element.css({
          transition: this.options.peekTransition,
          top: payload.height,
        });
      }
    },
  });
});
