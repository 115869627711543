class Controller {
  constructor(selectors = {}, features = {}) {
    this.elements = {};
    this.features = features;
    this.selectors = selectors;

    this.initializeElements = this.initializeElements.bind(this);

    this.init();
  }

  init() {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', this.initializeElements);
    } else {
      this.initializeElements();
    }

    window.onload = () => this.loaded();

    this.initialize();
  }

  initializeElements() {
    Object.entries(this.selectors).forEach(([key, selector]) => {
      this.elements[key] = $(selector);
    });

    this.ready();
  }

  // Empty methods to be overwritten by sub classes (interface like)

  // eslint-disable-next-line class-methods-use-this
  initialize() {}

  // eslint-disable-next-line class-methods-use-this
  ready() {}

  // eslint-disable-next-line class-methods-use-this
  loaded() {}
}

export default Controller;
