define(['common/main'], function() {
  var $window;
  var scrollHandler;
  var state;

  init();

  return {
    disable,
    enable,
    getState,
  };

  // ////////////////////////////

  function disable() {
    if (!scrollHandler) {
      return; // already disabled
    }
    $window.off('scroll', scrollHandler);
    scrollHandler = null;
  }

  function enable() {
    if (scrollHandler) {
      return; // already enabled
    }
    scrollHandler = _.throttle(handleScroll, 100);
    $window.on('scroll', scrollHandler);
  }

  function getState() {
    return state;
  }

  function handleScroll() {
    var prevDirection = state.direction;

    state.currentScroll = $window.scrollTop();
    state.direction = state.prevScroll < state.currentScroll ? 'down' : 'up';

    $.publish('scrollUtils.scroll', state);

    if (state.direction !== prevDirection) {
      $.publish('scrollUtils.directionChange', state);
    }

    state.prevScroll = state.currentScroll;
  }

  function init() {
    $window = $(window);
    scrollTop = $window.scrollTop();
    state = {
      direction: '',
      prevScroll: scrollTop,
      currentScroll: scrollTop,
    };
    enable();
  }
});
