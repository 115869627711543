/* istanbul ignore next */
const dynamicPagesMap = {
  destination: {
    loader: () => import(/* webpackChunkName: "pages.destination" */ 'common/components/pages/destination'),
  },
  trip: {
    loader: () => import(/* webpackChunkName: "pages.trip" */ 'common/components/pages/trip'),
  },
  exploreLanding: {
    loader: () => import(/* webpackChunkName: "pages.explore" */ 'common/components/pages/explore'),
  },
};

export default dynamicPagesMap;
